import { ImageShape, LogoShape } from '../contentTypes';

export const mockedFalconContent = {
  __type: 'aem-headless',
  id: '41bb5808-63c3-42c3-b003-7f961c520f59',
  path: '/narwhal/zeca-test-folder/ct-1-7-8-rc-2',
  contentPath: '/content/dam/teams/narwhal/zeca-test-folder/ct-1-7-8-rc-2',
  status: 'DRAFT',
  created: '2025-02-21T14:33:59.054Z',
  modified: '2025-02-21T15:13:51.307Z',
  title: 'ct-1.7.8-rc.2',
  description: '',
  model: {
    id: 'L2NvbmYvZmVhdHVyZS1hcHBzL29uZWF1ZGktZmVhdHVyZS1hcHAtbmFyLWVkaXRvcmlhbC1jb250ZW50LXRpbGVzLzEuNy44LXJjLjIvc2V0dGluZ3MvZGFtL2NmbS9tb2RlbHMvY29udGVudC10aWxlcw',
    path: '/conf/feature-apps/oneaudi-feature-app-nar-editorial-content-tiles/1.7.8-rc.2/settings/dam/cfm/models/content-tiles',
    title: 'Content Tiles',
    metadata: {
      baseUrl: 'https://fa-nar-content-tiles.cdn.prod.collab.apps.one.audi/v1.7.8-rc.2',
      id: '27fb1208-8e78-454f-8024-aff551729f85',
      serverSrc: 'fh/app.node.js',
      css: '[]',
      src: 'fh/app.js',
      version: '1.7.8-rc.2',
      claimPrimaryConsumer: 'false',
      sitemapService: '',
    },
  },
  fields: {
    layout_name: 'Basic - 2x1',
    layout_size: 'standard',
    tiles: [
      {
        id: '6248e875-3aab-4077-9148-232a0bb43200',
        path: '/narwhal/zeca-test-folder/tile-1-7-8-rc-2',
        contentPath: '/content/dam/teams/narwhal/zeca-test-folder/tile-1-7-8-rc-2',
        status: 'DRAFT',
        created: '2025-02-21T14:35:12.235Z',
        modified: '2025-02-21T15:13:17.221Z',
        title: 'tile-1.7.8-rc.2',
        description: '',
        model: {
          id: 'L2NvbmYvZmVhdHVyZS1hcHBzL29uZWF1ZGktZmVhdHVyZS1hcHAtbmFyLWVkaXRvcmlhbC1jb250ZW50LXRpbGVzLzEuNy44LXJjLjIvc2V0dGluZ3MvZGFtL2NmbS9tb2RlbHMvY29udGVudC10aWxlcy10aWxlcw',
          path: '/conf/feature-apps/oneaudi-feature-app-nar-editorial-content-tiles/1.7.8-rc.2/settings/dam/cfm/models/content-tiles-tiles',
          title: 'Content Tiles: tiles',
          metadata: {
            baseUrl: 'https://fa-nar-content-tiles.cdn.prod.collab.apps.one.audi/v1.7.8-rc.2',
            id: '27fb1208-8e78-454f-8024-aff551729f85',
            serverSrc: 'fh/app.node.js',
            css: '[]',
            src: 'fh/app.js',
            version: '1.7.8-rc.2',
            claimPrimaryConsumer: 'false',
            sitemapService: '',
          },
        },
        fields: {
          mediaAsset: {
            id: 'cfac89c2-cbe3-47db-a507-ba0cf94c8495',
            path: '/narwhal/zeca-test-folder/image-v-1-7-8-rc-2',
            contentPath: '/content/dam/teams/narwhal/zeca-test-folder/image-v-1-7-8-rc-2',
            status: 'DRAFT',
            created: '2025-02-21T15:05:20.908Z',
            modified: '2025-02-21T15:05:53.259Z',
            title: 'image-v1.7.8-rc.2',
            description: '',
            model: {
              id: 'L2NvbmYvZmVhdHVyZS1hcHBzL29uZWF1ZGktZmVhdHVyZS1hcHAtbmFyLWVkaXRvcmlhbC1jb250ZW50LXRpbGVzLzEuNy44LXJjLjIvc2V0dGluZ3MvZGFtL2NmbS9tb2RlbHMvY29udGVudC10aWxlcy1tZWRpYWltYWdl',
              path: '/conf/feature-apps/oneaudi-feature-app-nar-editorial-content-tiles/1.7.8-rc.2/settings/dam/cfm/models/content-tiles-mediaimage',
              title: 'Content Tiles: Media - Image',
              metadata: {
                baseUrl: 'https://fa-nar-content-tiles.cdn.prod.collab.apps.one.audi/v1.7.8-rc.2',
                id: '27fb1208-8e78-454f-8024-aff551729f85',
                serverSrc: 'fh/app.node.js',
                css: '[]',
                src: 'fh/app.js',
                version: '1.7.8-rc.2',
                claimPrimaryConsumer: 'false',
                sitemapService: '',
              },
            },
            fields: {
              assetType: 'image',
              alt: 'image',
              imageUrlSmall: {
                assetId: 'urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece',
                created: '2024-02-05T16:32:14.046Z',
                modified: '2024-04-19T13:46:33.811Z',
                height: 1920,
                width: 1920,
                mimeType: 'image/jpeg',
                path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
                contentPath:
                  '/content/dam/global/assets/teams/template-images/q8-tfsi-e_A206991_large.jpg',
                sizeInBytes: 117120,
                status: 'PUBLISHED',
              },
              imageUrlLarge: {
                assetId: 'urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece',
                created: '2024-02-05T16:32:14.046Z',
                modified: '2024-04-19T13:46:33.811Z',
                height: 1920,
                width: 1920,
                mimeType: 'image/jpeg',
                path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
                contentPath:
                  '/content/dam/global/assets/teams/template-images/q8-tfsi-e_A206991_large.jpg',
                sizeInBytes: 117120,
                status: 'PUBLISHED',
              },
            },
            __type: 'aem-headless',
          },
          logo_srcAsset: {
            assetId: 'urn:aaid:aem:925b6d93-9204-490e-9e19-645373fba82d',
            created: '2024-12-18T19:17:26.585Z',
            modified: '2024-12-18T19:17:31.102Z',
            height: 512,
            width: 512,
            mimeType: 'image/png',
            path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:925b6d93-9204-490e-9e19-645373fba82d/as/audi_logo_icon_145842.png?preferwebp=true',
            contentPath: '/content/dam/country/99999/assets/audi_logo_icon_145842.png',
            sizeInBytes: 11232,
            status: 'DRAFT',
          },
          logo_alt: 'audi logo',
          logo_heights_xs: '10px',
          logo_heights_s: '100px',
          logo_heights_m: '120px',
          logo_heights_l: '140px',
          logo_heights_xl: '150px',
          logo_heights_xxl: '160px',
          icon: '',
          heading_text: 'Label',
          heading_variant: 'order1',
          heading_htmlTag: 'h1',
          heading_embolden: true,
          subHeading_text: 'Subheading',
          subHeading_variant: 'order3',
          subHeading_htmlTag: 'h3',
          subHeading_embolden: true,
          CTA_href: 'https://www.audi.ca',
          CTA_variant: 'primary',
          CTA_text: 'canada audi',
          CTA_linkType: 'fullTile',
          CTA_openIn: 'New tab',
          CTA_newWindow: false,
          wltpKeys: [],
          disclaimers: [],
          theme: 'light',
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          zoomEnabled: true,
          fadeEnabled: true,
          fade_color: '#e9e9e9',
          fade_opacity: '90%',
          fade_height: '80%',
          fade_direction: 'top',
        },
        __type: 'aem-headless',
      },
      {
        id: '7ba4cd31-ffc2-4140-ab6e-d57d1a1b2571',
        path: '/narwhal/zeca-test-folder/tiles-v-1-7-8-rc-2-2',
        contentPath: '/content/dam/teams/narwhal/zeca-test-folder/tiles-v-1-7-8-rc-2-2',
        status: 'DRAFT',
        created: '2025-02-21T15:13:48.911Z',
        modified: '2025-02-21T15:16:19.321Z',
        title: 'tiles-v1.7.8-rc.2-2',
        description: '',
        model: {
          id: 'L2NvbmYvZmVhdHVyZS1hcHBzL29uZWF1ZGktZmVhdHVyZS1hcHAtbmFyLWVkaXRvcmlhbC1jb250ZW50LXRpbGVzLzEuNy44LXJjLjIvc2V0dGluZ3MvZGFtL2NmbS9tb2RlbHMvY29udGVudC10aWxlcy10aWxlcw',
          path: '/conf/feature-apps/oneaudi-feature-app-nar-editorial-content-tiles/1.7.8-rc.2/settings/dam/cfm/models/content-tiles-tiles',
          title: 'Content Tiles: tiles',
          metadata: {
            baseUrl: 'https://fa-nar-content-tiles.cdn.prod.collab.apps.one.audi/v1.7.8-rc.2',
            id: '27fb1208-8e78-454f-8024-aff551729f85',
            serverSrc: 'fh/app.node.js',
            css: '[]',
            src: 'fh/app.js',
            version: '1.7.8-rc.2',
            claimPrimaryConsumer: 'false',
            sitemapService: '',
          },
        },
        fields: {
          mediaAsset: {
            id: 'ccc4b5a6-c691-4ce7-83e5-4a1ce180f8a8',
            path: '/narwhal/zeca-test-folder/video-v-1-7-8-rc-2',
            contentPath: '/content/dam/teams/narwhal/zeca-test-folder/video-v-1-7-8-rc-2',
            status: 'DRAFT',
            created: '2025-02-21T15:14:12.321Z',
            modified: '2025-02-21T15:15:17.215Z',
            title: 'video-v1.7.8-rc.2',
            description: '',
            model: {
              id: 'L2NvbmYvZmVhdHVyZS1hcHBzL29uZWF1ZGktZmVhdHVyZS1hcHAtbmFyLWVkaXRvcmlhbC1jb250ZW50LXRpbGVzLzEuNy44LXJjLjIvc2V0dGluZ3MvZGFtL2NmbS9tb2RlbHMvY29udGVudC10aWxlcy1tZWRpYXZpZGVv',
              path: '/conf/feature-apps/oneaudi-feature-app-nar-editorial-content-tiles/1.7.8-rc.2/settings/dam/cfm/models/content-tiles-mediavideo',
              title: 'Content Tiles: Media - Video',
              metadata: {
                baseUrl: 'https://fa-nar-content-tiles.cdn.prod.collab.apps.one.audi/v1.7.8-rc.2',
                id: '27fb1208-8e78-454f-8024-aff551729f85',
                serverSrc: 'fh/app.node.js',
                css: '[]',
                src: 'fh/app.js',
                version: '1.7.8-rc.2',
                claimPrimaryConsumer: 'false',
                sitemapService: '',
              },
            },
            fields: {
              assetType: 'video',
              alt: 'video',
              autoplay: true,
              loop: true,
              videoUrlSmall: {
                assetId: 'urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9',
                created: '2024-01-15T22:10:33.863Z',
                modified: '2024-04-19T13:42:18.974Z',
                mimeType: 'video/mp4',
                path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
                contentPath:
                  '/content/dam/teams/solution-coding-architects/assets/Camflight_Q8_TFSIe_sl.mp4',
                sizeInBytes: 23167897,
                status: 'MODIFIED',
                title: 'Camera flight Q8 TFSIe',
              },
              videoUrlLarge: {
                assetId: 'urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9',
                created: '2024-01-15T22:10:33.863Z',
                modified: '2024-04-19T13:42:18.974Z',
                mimeType: 'video/mp4',
                path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
                contentPath:
                  '/content/dam/teams/solution-coding-architects/assets/Camflight_Q8_TFSIe_sl.mp4',
                sizeInBytes: 23167897,
                status: 'MODIFIED',
                title: 'Camera flight Q8 TFSIe',
              },
              posterImageUrlSmall: {
                assetId: 'urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516',
                created: '2024-03-22T08:09:25.327Z',
                modified: '2024-04-19T13:41:14.726Z',
                height: 489,
                width: 870,
                mimeType: 'image/jpeg',
                path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
                contentPath:
                  '/content/dam/microsites/one-audi/883x496_digital-services_a3_2020_2612_1.jpg',
                sizeInBytes: 56536,
                status: 'DRAFT',
              },
              posterImageUrlLarge: {
                assetId: 'urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516',
                created: '2024-03-22T08:09:25.327Z',
                modified: '2024-04-19T13:41:14.726Z',
                height: 489,
                width: 870,
                mimeType: 'image/jpeg',
                path: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
                contentPath:
                  '/content/dam/microsites/one-audi/883x496_digital-services_a3_2020_2612_1.jpg',
                sizeInBytes: 56536,
                status: 'DRAFT',
              },
            },
            __type: 'aem-headless',
          },
          heading_text: 'Test',
          heading_htmlTag: 'h6',
          heading_embolden: false,
          subHeading_text: 'Subheading',
          subHeading_variant: 'display1',
          subHeading_htmlTag: 'h2',
          subHeading_embolden: false,
          CTA_href: '/test/index.html',
          CTA_variant: 'secondary',
          CTA_text: 'test',
          CTA_linkType: 'noLink',
          CTA_openIn: 'Same tab',
          CTA_newWindow: false,
          wltpKeys: [],
          disclaimers: [],
          theme: 'light',
          horizontalAlignment: 'center',
          verticalAlignment: 'center',
          zoomEnabled: true,
          fadeEnabled: false,
          fade_direction: 'top',
        },
        __type: 'aem-headless',
      },
    ],
  },
};

export const mockedMappedContent = {
  layout: {
    name: 'Basic - 2x1',
    size: 'standard',
  },
  tiles: [
    {
      CTA: {
        href: 'https://www.audi.ca',
        variant: 'primary',
        text: 'canada audi',
        linkType: 'fullTile',
        openIn: 'New tab',
      },
      heading: {
        text: 'Label',
        variant: 'order1',
        htmlTag: 'h1',
        embolden: true,
      },
      horizontalAlignment: 'center',
      icon: '',
      logo: {
        alt: 'audi logo',
        src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:925b6d93-9204-490e-9e19-645373fba82d/as/audi_logo_icon_145842.png?preferwebp=true',
        heights: {
          xs: '10px',
          s: '100px',
          m: '120px',
          l: '140px',
          xl: '150px',
          xxl: '160px',
        },
      },
      wltpKeys: [],
      disclaimers: [],
      media: {
        imageEnabled: true,
        videoEnabled: false,
        image: {
          alt: 'image',
          srcSet: [
            {
              bp: 'xxl',
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
            },
            {
              bp: 'xl',
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
            },
            {
              bp: 'l',
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
            },
            {
              bp: 'm',
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
            },
            {
              bp: 's',
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
            },
            {
              bp: 'xs',
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:c4989bc1-eff5-4fff-b96e-c56917f42ece/as/q8-tfsi-e_A206991_large.jpg?preferwebp=true',
            },
          ],
        },
        video: {
          alt: '',
          autoplay: false,
          loop: false,
          srcs: {
            xs: {
              src: '',
              poster: '',
            },
            s: {
              src: '',
              poster: '',
            },
            m: {
              src: '',
              poster: '',
            },
            l: {
              src: '',
              poster: '',
            },
            xl: {
              src: '',
              poster: '',
            },
            xxl: {
              src: '',
              poster: '',
            },
          },
        },
        fadeEnabled: true,
        fade: {
          color: '#e9e9e9',
          opacity: '0.9',
          height: '80%',
          direction: 'top',
        },
        zoomEnabled: true,
      },
      subHeading: {
        text: 'Subheading',
        variant: 'order3',
        htmlTag: 'h3',
        embolden: true,
      },
      theme: 'light',
      verticalAlignment: 'center',
      zoomEnabled: true,
      fadeEnabled: true,
      fade: {
        color: '#e9e9e9',
        opacity: '0.9',
        height: '80%',
        direction: 'top',
      },
    },
    {
      CTA: {
        href: '/test/index.html',
        variant: 'secondary',
        text: 'test',
        linkType: 'noLink',
        openIn: 'Same tab',
      },
      heading: {
        text: 'Test',
        variant: 'order1',
        htmlTag: 'h6',
        embolden: false,
      },
      horizontalAlignment: 'center',
      icon: '',
      logo: {
        alt: '',
        src: '',
        heights: {
          xs: '',
          s: '',
          m: '',
          l: '',
          xl: '',
          xxl: '',
        },
      },
      wltpKeys: [],
      disclaimers: [],
      media: {
        imageEnabled: false,
        videoEnabled: true,
        image: {
          alt: '',
          srcSet: [
            {
              src: '',
              bp: 'xs',
            },
            {
              src: '',
              bp: 's',
            },
            {
              src: '',
              bp: 'm',
            },
            {
              src: '',
              bp: 'l',
            },
            {
              src: '',
              bp: 'xl',
            },
            {
              src: '',
              bp: 'xxl',
            },
          ],
        },
        video: {
          alt: 'video',
          autoplay: true,
          loop: true,
          srcs: {
            xs: {
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
              poster:
                'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
            },
            s: {
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
              poster:
                'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
            },
            m: {
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
              poster:
                'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
            },
            l: {
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
              poster:
                'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
            },
            xl: {
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
              poster:
                'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
            },
            xxl: {
              src: 'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:38959b91-d0e8-426d-92a7-f143d520ebd9/original/as/Camflight_Q8_TFSIe_sl.mp4',
              poster:
                'https://delivery-p119811-e1162434.adobeaemcloud.com/adobe/assets/urn:aaid:aem:6b4e3649-11c2-4e3e-b0f0-f86f81590516/as/883x496_digital-services_a3_2020_2612_1.jpg?preferwebp=true',
            },
          },
        },
        fadeEnabled: false,
        fade: {
          color: '',
          opacity: '',
          height: '',
          direction: 'bottom',
        },
        zoomEnabled: true,
      },
      subHeading: {
        text: 'Subheading',
        variant: 'display1',
        htmlTag: 'h2',
        embolden: false,
      },
      theme: 'light',
      verticalAlignment: 'center',
      zoomEnabled: true,
      fadeEnabled: false,
      fade: {
        color: '',
        opacity: '',
        height: '',
        direction: 'bottom',
      },
    },
  ],
};

export const emptyLogo = {
  alt: '',
  src: '',
  srcAsset: '',
  heights: {
    xs: '18',
    s: '18',
    m: '24',
    l: '36',
    xl: '48',
    xxl: '60',
  },
} as LogoShape;

export const emptyImage = {
  alt: '',
  srcSet: [
    {
      bp: 'xs',
      src: '',
      srcAsset: '',
    },
  ],
} as ImageShape;

export const emptyVideo = {
  alt: '',
  autoplay: true,
  loop: true,
  srcs: {
    xs: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    s: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    m: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    l: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    xl: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    xxl: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
  },
};
