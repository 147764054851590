import type {
  EditorJsonContent,
  FaceAsset,
  FadeDirection,
  FalconContent,
  ImageSrcSet,
  LogoShape,
  TileAsset,
  TilesContent,
} from '../contentTypes';
import { emptyLogo } from './testContent';
import { tileModelToNemo } from './tileModelToNemo';
import { percentageToDecimal } from './utils';

export const mapHeadlessContent = (content: FalconContent): TilesContent => {
  const { layout_name: name, layout_size: size, tiles } = content.fields;
  const appContent: TilesContent = {
    layout: {
      name,
      size,
    },
    tiles: tiles?.map((tile) => {
      return tileModelToNemo(tile);
    }),
  };
  return appContent;
};

const mapAssetContent = (asset: FaceAsset): TileAsset => {
  if (asset?.assetType === 'image') {
    const smallSrcSet = ['m', 's', 'xs'].map((bp) => ({
      bp,
      src: asset.imageUrlSmall.assetValue,
      srcAsset: '',
    })) as ImageSrcSet;
    const largeSrcSet = ['xxl', 'xl', 'l'].map((bp) => ({
      bp,
      src: asset.imageUrlLarge.assetValue,
      srcAsset: '',
    })) as ImageSrcSet;
    return {
      imageEnabled: true,
      videoEnabled: false,
      image: {
        alt: asset.alt,
        srcSet: [...largeSrcSet, ...smallSrcSet],
      },
      video: {
        alt: '',
        autoplay: false,
        loop: false,
        srcs: {
          xs: { src: '', poster: '' },
          s: { src: '', poster: '' },
          m: { src: '', poster: '' },
          l: { src: '', poster: '' },
          xl: { src: '', poster: '' },
          xxl: { src: '', poster: '' },
        },
      },
    };
  }
  const smallSrcShape = {
    src: asset.videoUrlSmall.assetValue,
    poster: asset.posterImageUrlSmall.assetValue,
  };
  const largeSrcShape = {
    src: asset.videoUrlLarge.assetValue,
    poster: asset.posterImageUrlLarge.assetValue,
  };
  return {
    imageEnabled: false,
    videoEnabled: true,
    image: {
      alt: '',
      srcSet: [],
    },
    video: {
      alt: asset.alt,
      autoplay: asset.autoplay,
      loop: asset.loop,
      srcs: {
        xs: smallSrcShape,
        s: smallSrcShape,
        m: smallSrcShape,
        l: largeSrcShape,
        xl: largeSrcShape,
        xxl: largeSrcShape,
      },
    },
  };
};

export const mapFaceContent = (content: EditorJsonContent): TilesContent => {
  const { layout, tiles } = content;
  const newTiles = tiles.map((tile) => {
    const {
      horizontalAlignment,
      verticalAlignment,
      CTA,
      mediaAsset,
      logo,
      disclaimers,
      zoomEnabled,
      fadeEnabled,
      fade,
      wltpKeys,
      ...restTile
    } = tile;
    const { linkType: CTAlinkType, openIn, ...restCTA } = CTA;

    const newLogo = (() => {
      if (logo) {
        const { src: logoSrc, srcAsset: logoSrcAsset, ...restLogo } = logo;
        return {
          src: (typeof logoSrcAsset === 'object' && logoSrcAsset.assetValue) || logoSrc || '',
          srcAsset: { assetValue: '' },
          ...restLogo,
        } as LogoShape;
      }
      return emptyLogo;
    })();
    const { newFade } = (() => {
      if (fadeEnabled) {
        return {
          newFade: {
            opacity: percentageToDecimal(fade?.opacity || '0%'),
            color: fade?.color || '',
            direction: fade?.direction || 'bottom',
            height: fade?.height || '',
          },
        };
      }
      return {
        newFade: {
          opacity: '',
          color: '',
          direction: 'bottom' as FadeDirection,
          height: '',
        },
      };
    })();

    return {
      ...restTile,
      logo: newLogo,
      mediaAsset,
      media: {
        ...mapAssetContent(mediaAsset!),
        fadeEnabled,
        zoomEnabled,
        fade: newFade,
      },
      CTA: {
        linkType: CTAlinkType,
        openIn,
        ...restCTA,
      },
      disclaimers,
      horizontalAlignment,
      verticalAlignment,
      zoomEnabled,
      fade,
      fadeEnabled,
      wltpKeys: wltpKeys || [],
    };
  });

  return { layout, tiles: newTiles };
};
