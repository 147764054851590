/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueFormatterFeatureServiceInterfaceV1 } from '@oneaudi/vue-formatter-service/lib/cjs/v1/vue-formatter-feature-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { createContext } from 'react';

type ContentContextState = {
  content: any;
  vueFormatterService: VueFormatterFeatureServiceInterfaceV1;
  localeService: LocaleServiceV1;
};

export const ContentContext = createContext<Partial<ContentContextState>>({});
