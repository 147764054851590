/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useContext } from 'react';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { Text } from '@oneaudi/unified-web-components';
import { v4 as uuid } from 'uuid';
import { VueServiceHelper, WltpProps } from './consumptionEmission';
import { WLTPContainer, MediaDisclaimerContainer } from './style';
import { ContentContext } from '../../context/ContentContext';

type WLTPComponentsProps = {
  wltpTextValues: string[];
  materialDisclaimer: string[];
  theme: string;
};

const WLTPComponent: React.FC<WLTPComponentsProps> = (props) => {
  const { materialDisclaimer, wltpTextValues, theme } = props;
  const context: any = useContext(ContentContext);

  const { vueFormatterService, localeService } = context;

  if (!vueFormatterService) {
    return <div />;
  }

  const [vueTexts, setVueTexts] = React.useState([] as WltpProps[]);

  useEffect(() => {
    wltpTextValues &&
      VueServiceHelper.getConsumptionAndEmission(
        wltpTextValues,
        setVueTexts,
        vueFormatterService,
        localeService,
      );
  }, [wltpTextValues]);

  return (
    <div>
      {materialDisclaimer?.length > 0 ||
      (vueTexts && wltpTextValues.length > 0) ||
      typeof materialDisclaimer === 'string' ? (
        <WLTPContainer className={`fg-wltp-content `} key="fg-wltp-content" theme={theme}>
          {vueTexts &&
            vueTexts?.map(
              (
                {
                  formattedConsumption,
                  formattedEmission,
                  formattedCo2Class,
                  formattedDischargedCo2Class,
                  formattedDischargedConsumption,
                },
                index,
              ) => (
                <Text tag="p" variant={{ type: 'copy', order: '2', style: 'normal' }} key={index}>
                  {formattedConsumption && (
                    <span className="fg-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedConsumption};
                    </span>
                  )}
                  {formattedEmission && (
                    <span className="fg-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedEmission};
                    </span>
                  )}
                  {formattedCo2Class && (
                    <span className="fg-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedCo2Class}
                      {formattedDischargedConsumption || formattedDischargedCo2Class ? ';' : ''}
                    </span>
                  )}
                  {formattedDischargedConsumption && (
                    <span className="fg-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedDischargedConsumption}
                      {formattedDischargedCo2Class ? ';' : ''}
                    </span>
                  )}
                  {formattedDischargedCo2Class && (
                    <span className="fg-wltp-items" tabIndex={-1} key={uuid()}>
                      {formattedDischargedCo2Class}
                    </span>
                  )}
                </Text>
              ),
            )}

          <MediaDisclaimerContainer
            style={{ display: materialDisclaimer.length ? 'block' : 'none' }}
            key="materialDisclaimer"
          >
            {materialDisclaimer &&
              materialDisclaimer?.map((disclaimer, index) => (
                <Text key={index} tag="p" variant={{ type: 'copy', order: '2', style: 'normal' }}>
                  {renderTextWithFootnotesReferences(disclaimer)}
                </Text>
              ))}
          </MediaDisclaimerContainer>
        </WLTPContainer>
      ) : null}
    </div>
  );
};

export default WLTPComponent;
