const heroVideoSmall =
  'https://www.audiusa.com/content/dam/nemo/us/models/A3/2020-A3Sedan/header-transition/2017 A3 Sedan V06 Edited-May27-1.mp4';
const heroVideoLarge =
  'https://www.audiusa.com/content/dam/nemo/us/models/A3/2020-A3Sedan/header-transition/2017%20A3%20Sedan%20V06%20Edited-May27-1.mp4';

const heroVideoPosterSmall =
  'https://www.audiusa.com/dam/nemo/us/models/A3/2020-A3Sedan/header-transition/1920x1080/1920x1080_headerstill_2017%20A3_v06-min.jpg?output-format=webp&downsize=767px:*';
const heroVideoPosterLarge =
  'https://www.audiusa.com/dam/nemo/us/models/A3/2020-A3Sedan/header-transition/1920x1080/1920x1080_headerstill_2017%20A3_v06-min.jpg?output-format=jpegxr&downsize=1439px:*';

const srcs = {
  xs: {
    src: heroVideoSmall,
    poster: heroVideoPosterSmall,
  },
  s: {
    src: heroVideoSmall,
    poster: heroVideoPosterSmall,
  },
  m: {
    src: heroVideoSmall,
    poster: heroVideoPosterSmall,
  },
  l: {
    src: heroVideoLarge,
    poster: heroVideoPosterLarge,
  },
  xl: {
    src: heroVideoLarge,
    poster: heroVideoPosterLarge,
  },
  xxl: {
    src: heroVideoLarge,
    poster: heroVideoPosterLarge,
  },
};

export const logo = {
  src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAESElEQVR42u2YfWiWVRjGX6MwDYYa+McEQRJMIg1RSGPRH4KWlU5B09lcms6JsihMjSjIdGV+UmaZgh8IRjkxS1Fx85M2nGtJWbNNp662NTU/NhXTXq8D19Gbx3Pe5zz9KfcFl/Pc5/zOe57nfD+plEqlUqlUKpVKpVKpVCqVSnWfKJ1OPw1/BB+EW+B2+Cy8C34X7uNgnoLnw+XwX3Ab/5bBH8D9HExf+D14D9xIphk+AJfAAx3MY/BceAd8mszf8GF4MZyT5EH7wDvTYVpDpjO8JZD5Gu4Id4A3BDLb4a78rZWBzF64f9zDjoL/FdAf8KdwEfwq/Aa8jj1u9TvfspX5/xfwTDgfngWv5uiwOgWfEOkmeC1cTGYGH6xelDEj5VeRPg9vhN8kUwgvh3+LPPgE38OOEYUumAfM8GJMj06HWwVTxx9+wMM8CBfAZwRjhu5U+OEMvzUePi6Yi3yJWRmYcXxBVgXRAj0ivZodOPwbBVcdyMgeaAhkDgqmNZDpBh8TXG+ZuZvB6/CjgRVudMybBTHMEt86kIF528FsC2yjGYmXyVTIVdJqUmBF2YIZzTmX5vx/xMNkwf+x3OscdlbdPcxD8BWWmQMPd/ZY5raOFMygFLceo/oEK/lsO59EzCrPw0xm/k0Ru8pYsYd5WdTbibEmpucnaK8d2p+nuHcZlSSo4DsyX4nYgWgswqxj/h4RK2Vsi4dZxvyfHNOiLEF737lTD1dko3EJKviFzPTQhoiFp8TRkOqYF/uliE1MsuCRGUHmkkncYuL5BBWcig5fnpbuLg73MkeZP0/Eiuxe7mH2Mn+RY062Jmhvzp3phH/+YWJsggrsAaBQxD5hrNzDHGL+QhGbx1iNh9nO/FUilmcPOAna+wKZyybx4/9YBH5wNKSMsbUx29hOEfuGsa0eZgXzj4jYx4ztS9Beu7X9nOJh26g2QQW2Z86L2K1MWxvi05h/Q8TaGHvLw4wWq3RHxs5ER0pAe6vJrDaJfqLSsYEV9BTMMB4Z09xnszxMV3m+hV8S6WwPYy4Z1+zWBT8rmMcD2zpMMIOjW0qb7+DgqOhbxwloaQzjuulsimHedzC7A9tozu/n7lknkOglKjtmr2IBFf4puJpAplberAKZw4I5F8h0gisF90S0QL7INJeC3AyVdeBq2Ry5JubGNGKM2NLSfGGvxDAvmsUmcpN7zfRezL5bJ5gZvoJ5kaFTw68O5i6cyzPwZ/BJUeZs5Jp4nAthARnTuKV8IVYtkdFRxxV5Cpl8rsbytmO2z4bI767iYpjLtn8IV0WeYWrcUBgg5nScNpu7rLlh8dNPiL6Hu/AmUxrIlPMKa+bk+kCmAh6S5FPPUC4yVZz47Ty4H+KFY4CDeYbn30p+Y2pnb1bw6DnYwQw0JynO02YyZsQc4deW5xzMk+zN/bzot/MLSDW/tgzXr5IqlUqlUqlUKpVKpVKpVPetbgOAHtHA6ugp4QAAAABJRU5ErkJggg==',
  alt: '',
  heights: {
    xs: '18',
    s: '18',
    m: '24',
    l: '36',
    xl: '48',
    xxl: '60',
  },
};

export const materialDisclaimerLight = {
  xs: {
    position: 'bottomLeft',
    theme: 'light',
    disclaimer: 'disclaimers!',
  },
  s: {
    position: 'bottomRight',
    theme: 'light',
    disclaimer: 'disclaimers!',
  },
  m: {
    position: 'topLeft',
    theme: 'light',
    disclaimer: 'disclaimers!',
  },
  l: {
    position: 'topRight',
    theme: 'light',
    disclaimer: 'disclaimers!',
  },
  xl: {
    position: 'bottomLeft',
    theme: 'light',
    disclaimer: 'disclaimers!',
  },
  xxl: {
    position: 'bottomRight',
    theme: 'light',
    disclaimer: 'disclaimers!',
  },
};

export const materialDisclaimerDark = {
  xs: {
    position: 'bottomLeft',
    theme: 'dark',
    disclaimer: 'disclaimers!',
  },
  s: {
    position: 'bottomRight',
    theme: 'dark',
    disclaimer: 'disclaimers!',
  },
  m: {
    position: 'topLeft',
    theme: 'dark',
    disclaimer: 'disclaimers!',
  },
  l: {
    position: 'topRight',
    theme: 'dark',
    disclaimer: 'disclaimers!',
  },
  xl: {
    position: 'bottomLeft',
    theme: 'dark',
    disclaimer: 'disclaimers!',
  },
  xxl: {
    position: 'bottomRight',
    theme: 'dark',
    disclaimer: 'disclaimers!',
  },
};

export const basicTile = (num) => ({
  logo: {},
  icon: '',
  heading: {
    text: 'Content Tiles',
    variant: 'order1',
    htmlTag: 'h2',
  },
  subHeading: {
    text: 'Contentment contained in tile form',
    variant: 'order3',
    htmlTag: 'p',
  },
  verticalAlignment: 'center',
  horizontalAlignment: 'center',
  fade: { color: '#000000', direction: 'bottom', height: '70%', opacity: '60%' },
  fadeEnabled: true,
  media: {
    fadeEnabled: true,
    fade: {
      direction: 'top',
      height: '80%',
      opacity: '1',
    },
    imageEnabled: true,
    image: {
      alt: 'Image of an Audi',
      srcSet: [
        {
          bp: 'xs',
          src:
            num % 2 === 0
              ? 'https://www.audiusa.com/content/dam/nemo/us/Homepage/1277x718_M04.1_exterior_3-4_front_14_final_RGB-min.jpg?output-format=webp&downsize=1277px:*'
              : 'https://www.audiusa.com/dam/nemo/us/models/e-tron/e-tron-sportback/730x730-overview-images/730x730-Audi-etronsb-accessories-min.jpg?output-format=webp&downsize=730px:*',
        },
      ],
    },
    videoEnabled: false,
    video: {
      autoplay: true,
      loop: true,
      srcs,
    },
  },
  CTA: {
    text: 'Click here to vist a thing',
    href: 'testing',
    variant: 'text',
    linkType: 'fullTile',
  },
  theme: 'dark',
});

export const props = {
  layout: {
    name: 'Basic - 2x1',
    size: 'standard',
  },
  tiles: [basicTile(1), basicTile(2)],
};
