/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
// autogenerated names don't follow camelCase
import type {
  FadeDirection,
  ImageSrcSet,
  MediaImageFragment,
  MediaVideoFragment,
  Tile,
  TileFragment,
} from '../contentTypes';
import { percentageToDecimal } from './utils';

// Image or Video from mediaAsset field
const getMediaAssetContent = (mediaAsset: MediaImageFragment | MediaVideoFragment) => {
  if (mediaAsset?.fields?.assetType === 'image') {
    const { imageUrlSmall, imageUrlLarge } = mediaAsset.fields;
    const smallSrcSet = ['m', 's', 'xs'].map((bp) => ({
      bp,
      src: imageUrlSmall.path,
    })) as ImageSrcSet;
    const largeSrcSet = ['xxl', 'xl', 'l'].map((bp) => ({
      bp,
      src: imageUrlLarge.path,
    })) as ImageSrcSet;
    return {
      imageEnabled: true,
      videoEnabled: false,
      image: {
        alt: mediaAsset?.fields?.alt,
        srcSet: [...largeSrcSet, ...smallSrcSet],
      },
      video: {
        alt: '',
        autoplay: false,
        loop: false,
        srcs: {
          xs: { src: '', poster: '' },
          s: { src: '', poster: '' },
          m: { src: '', poster: '' },
          l: { src: '', poster: '' },
          xl: { src: '', poster: '' },
          xxl: { src: '', poster: '' },
        },
      },
    };
  }
  const { autoplay, loop, videoUrlSmall, videoUrlLarge, posterImageUrlSmall, posterImageUrlLarge } =
    mediaAsset.fields;
  const smallSrcShape = {
    src: videoUrlSmall.path,
    poster: posterImageUrlSmall.path,
  };
  const largeSrcShape = {
    src: videoUrlLarge.path,
    poster: posterImageUrlLarge.path,
  };
  return {
    imageEnabled: false,
    videoEnabled: true,
    image: {
      alt: '',
      srcSet: [
        {
          src: '',
          bp: 'xs',
        },
        {
          src: '',
          bp: 's',
        },
        {
          src: '',
          bp: 'm',
        },
        {
          src: '',
          bp: 'l',
        },
        {
          src: '',
          bp: 'xl',
        },
        {
          src: '',
          bp: 'xxl',
        },
      ] as ImageSrcSet,
    },
    video: {
      alt: mediaAsset?.fields?.alt,
      autoplay,
      loop,
      srcs: {
        xs: smallSrcShape,
        s: smallSrcShape,
        m: smallSrcShape,
        l: largeSrcShape,
        xl: largeSrcShape,
        xxl: largeSrcShape,
      },
    },
  };
};

export const tileModelToNemo = (tile: TileFragment): Tile => {
  const {
    theme,
    icon,
    mediaAsset,

    logo_alt,
    logo_srcAsset,

    logo_heights_xs,
    logo_heights_s,
    logo_heights_m,
    logo_heights_l,
    logo_heights_xl,
    logo_heights_xxl,

    heading_text,
    heading_variant,
    heading_htmlTag,
    heading_embolden,

    subHeading_text,
    subHeading_variant,
    subHeading_htmlTag,
    subHeading_embolden,

    CTA_href,
    CTA_variant,
    CTA_text,
    CTA_linkType,
    CTA_openIn,

    horizontalAlignment,

    verticalAlignment,

    wltpKeys,
    disclaimers,

    zoomEnabled,
    fadeEnabled,
    fade_color,
    fade_opacity,
    fade_height,
    fade_direction,
  } = tile.fields;

  const newFade = (() => {
    if (fadeEnabled) {
      return {
        color: fade_color || '',
        opacity: percentageToDecimal(fade_opacity) || '',
        height: fade_height || '',
        direction: fade_direction || 'bottom',
      };
    }
    return {
      color: '',
      opacity: '',
      height: '',
      direction: 'bottom' as FadeDirection,
    };
  })();

  const mediaContent = getMediaAssetContent(mediaAsset);

  return {
    CTA: {
      href: CTA_href || '',
      variant: CTA_variant || 'secondary',
      text: CTA_text || '',
      linkType: CTA_linkType,
      openIn: CTA_openIn,
    },
    heading: {
      text: heading_text || '',
      variant: heading_variant || 'order1',
      htmlTag: heading_htmlTag || 'h1',
      embolden: heading_embolden,
    },
    horizontalAlignment,
    icon: icon || '',
    logo: {
      alt: logo_alt || '',
      src: logo_srcAsset?.path || '',
      heights: {
        xs: logo_heights_xs || '',
        s: logo_heights_s || '',
        m: logo_heights_m || '',
        l: logo_heights_l || '',
        xl: logo_heights_xl || '',
        xxl: logo_heights_xxl || '',
      },
    },
    wltpKeys,
    disclaimers,
    media: {
      ...mediaContent,
      fadeEnabled,
      fade: newFade,
      zoomEnabled,
    },
    subHeading: {
      text: subHeading_text || '',
      variant: subHeading_variant || 'order2',
      htmlTag: subHeading_htmlTag || 'h2',
      embolden: subHeading_embolden,
    },
    theme,
    verticalAlignment,
    zoomEnabled,
    fadeEnabled,
    fade: newFade,
  };
};
